<template>
    <div class="ads-orders">
        <section class="orders-table">
            <ek-table
                :items="activityAdsOrders"
                :columns="columns"
                @details="navigateToAdsOrderDetails"
                no_delete
                no_select
            >
                <template slot="items.createdDate" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>
                <template slot="items.responseDate" slot-scope="{ value }">
                    {{
                        value
                            ? new Date(value)
                                  .toLocaleString("en-UK")
                                  .split(",")
                                  .reverse()
                                  .join(" ")
                            : "-"
                    }}
                </template>
                <template slot="items.dashResponse" slot-scope="{ value }">
                    <StatusBadge
                        class="orders-table__status-badge"
                        :statusList="orderStatusList"
                        :selectedStatusNumber="value"
                    />
                </template>
            </ek-table>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge";

export default {
    computed: {
        ...mapGetters(["activityAdsOrders"]),
        ...mapState({
            orderStatusList: (state) => state.orders.orderStatusList,
        }),
    },
    components: {
        StatusBadge,
    },
    props: {
        id: String
    },
    data: () => ({
        columns: [
            {
                label: " اسم الفعالية",
                field: "name",
            },
            {
                label: "تاريخ الطلب",
                field: "createdDate",
            },
            {
                label: "تاريخ الاستجابة",
                field: "responseDate",
            },
            {
                label: "حالة الطلب",
                field: "dashResponse",
            },
            {
                label: "تفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    }),
    methods: {
        navigateToAdsOrderDetails({ row }) {
            this.$router.push(`/admin/activities/${this.id}/ads/${row.id}`);
        },
    },
};
</script>

<style lang="scss" scoped></style>
