<template>
    <div v-if="data && data.chartOptions">
        <vue-apex-charts
            :height="height"
            :options="data.chartOptions"
            :series="data.series"
        />
    </div>
</template>

<script>
import {} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";

export default {
    components: {
        VueApexCharts,
    },
    props: {
        data: {},
        height: Number,
    },
};
</script>
