<template>
  <div>
    <b-row>
      <b-col class="details">
        <ActivityDetailsForm></ActivityDetailsForm>
      </b-col>
    </b-row>

    <b-row class="row2">
      <b-col class="comments" cols="12" lg="4">
        <ActivityComments :comments="activitiesDetailsDto.commnets"></ActivityComments>
      </b-col>
      <b-col class="statistics" cols="12" lg="8">
        <ActivityStatistics :cols="5" :stats="activityStats"></ActivityStatistics>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <ActivityFollowersAndVisitors
          :followers="activitiesDetailsDto.followers"
          :visitors="activitiesDetailsDto.visitors"
        />
      </b-col>
      <b-col lg="8">
        <ActivityOrderTable :id="id" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ActivityDetailsForm from "../components/ActivityDetailsForm.vue";
import ActivityStatistics from "../components/ActivityStatistics.vue";
import ActivityComments from "../components/ActivityComments.vue";
import ActivityOrderTable from "../components/ActivityOrderTable.vue";
import ActivityFollowersAndVisitors from "../components/ActivityFollowersAndVisitors.vue";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  components: {
    ActivityDetailsForm,
    ActivityStatistics,
    ActivityComments,
    ActivityOrderTable,
    ActivityFollowersAndVisitors,
  },
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      activitiesDetailsDto: (state) => state.activities.activitiesDetailsDto,
      activityOrdersTable: (state) => state.activities.activityOrdersTable,
    }),

    ...mapGetters(["activitesList", "activityStats"]),
  },
  created() {
    this.getActivityOrders(this.id);
    this.getActivitiesDetails(this.id);
  },
  methods: {
    ...mapActions(["getActivitiesDetails", "getActivityOrders"]),
  },
};
</script>
<style lang="scss" scoped>
.row2 {
  @media (min-width: 200px) and (max-width: 992px) {
    flex-direction: column !important;
    gap: 1rem !important;
  }
}
</style>
