<template>
    <section class="activity-details-orders">
        <b-card>
            <b-tabs class="activity-details-orders-tabs" justified>
                <b-row class="justify-content-between">
                    <b-tab
                        title="الاشتراكات"
                        active
                        @click="setActiveActivityOrderTab(0)"
                    ></b-tab>

                    <b-tab
                        title="الاشعارات"
                        @click="setActiveActivityOrderTab(1)"
                    ></b-tab>

                    <b-tab
                        title="الإعلانات"
                        @click="setActiveActivityOrderTab(2)"
                    ></b-tab>

                    <b-tab
                        title="الوظائف"
                        @click="setActiveActivityOrderTab(3)"
                    ></b-tab>
                </b-row>
            </b-tabs>
            <component v-bind:is="selectOrderTable" :id="id" />
        </b-card>
    </section>
</template>

<script>
import ActivitySubscriptionsTable from "./activityOrderTables/ActivitySubscriptionsTable.vue";
import ActivityAdsTable from "./activityOrderTables/ActivityAdsTable.vue";
import ActivityJobsTable from "./activityOrderTables/ActivityJobsTable.vue";
import ActivityNotificationsTable from "./activityOrderTables/ActivityNotificationsTable.vue";

import { mapState, mapGetters, mapMutations } from "vuex";

export default {
    components: {
        ActivitySubscriptionsTable,
        ActivityNotificationsTable,
        ActivityAdsTable,
        ActivityJobsTable,
    },
    props: {
        id: String,
    },
    created() {
        this.Set_Active_Activity_Order_Tab(0);
    },
    computed: {
        ...mapState({
            activeActivityOrderTab: (state) =>
                state.activities.activeActivityOrderTab,
        }),
        ...mapGetters(["activitesList"]),
        selectOrderTable() {
            let component = "";
            switch (this.activeActivityOrderTab) {
                case 0:
                    component = ActivitySubscriptionsTable;
                    break;
                case 1:
                    component = ActivityNotificationsTable;
                    break;
                case 2:
                    component = ActivityAdsTable;
                    break;
                case 3:
                    component = ActivityJobsTable;
                    break;
                default:
                    component = ActivitySubscriptionsTable;
            }
            return component;
        },
    },
    methods: {
        ...mapMutations(["Set_Active_Activity_Order_Tab"]),
        setActiveActivityOrderTab(activityNumber) {
            this.Set_Active_Activity_Order_Tab(activityNumber);
        },
    },
};
</script>

<style lang="scss" scoped>
.activity-details-orders-tabs {
    &::v-deep {
        ul.nav {
            border-bottom: 1.5px solid #e9ecef;
        }
    }
}
</style>
