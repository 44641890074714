<template>
  <div>
    <b-card class="position-relative" header-class="p-0">
      <template #header>
        <!-- start activity form action buttons -->
        <div class="d-flex w-100 justify-content-end p-25" style="gap: 1rem">
          <!-- Edit Activity -->
          <b-button variant="flat-primary" @click="openEdit()" class="p-25">
            <unicon name="edit" height="18px" fill="gray"></unicon>
          </b-button>
          <!-- deleteActivity -->
          <b-button variant="flat-primary" class="p-25">
            <unicon
              name="trash-alt"
              height="18px"
              fill="gray"
              @click="deleteActivity(activitiesDetailsDto.accountId)"
            ></unicon>
          </b-button>
          <!-- Ban , Block Activity -->
          <b-button variant="flat-primary" class="p-25" @click="toggleBlock">
            <unicon
              name="ban"
              height="18px"
              :fill="activitiesDetailsDto.isBlock ? 'red' : 'gray'"
            ></unicon>
          </b-button>
          <!-- end activity form action buttons -->

          <!--start edit activity form  -->
          <ek-modal ref="edit" noHeader noFooter>
            <ValidationObserver ref="detailsActivity">
              <b-card>
                <b-row>
                  <b-col cols="12">
                    <div class="fill flex-center">
                      <ek-image-picker
                        class="activity-info-img mt-1 w-100"
                        required
                        @imageDeleted="activitiesDetailsDto.removeFile = true"
                        :value="activitiesDetailsDto.imageProfile"
                        @input="activitiesDetailsDto.file = $event"
                      ></ek-image-picker>
                    </div>
                  </b-col>
                  <b-col cols="6">
                    <ek-input-text
                      label="اسم الفعالية"
                      name="اسم الفعالية"
                      placeholder="ادخل اسم الفعالية"
                      :rules="[
                        {
                          type: 'required',
                        },
                      ]"
                      v-model="activitiesDetailsDto.accountName"
                    ></ek-input-text>
                    <ek-input-select
                      :options="commercialActivities"
                      label="النشاط التجاري"
                      name="النشاط التجاري"
                      placeholder="ادخل النشاط التجاري"
                      v-model="activitiesDetailsDto.commercialActivityId"
                      :rules="[
                        {
                          type: 'required',
                        },
                      ]"
                    ></ek-input-select>
                    <ek-input-select
                      @input="updateCities($event)"
                      name="البلد"
                      label="الدولة"
                      placeholder="اختر الدولة"
                      :options="countries"
                      textLabel="langName"
                      v-model="activitiesDetailsDto.countryId"
                    ></ek-input-select>
                    <ek-input-select
                      v-if="country == 'سوريا'"
                      :rules="[
                        {
                          type: 'required',
                          message: 'المدينة مطلوبة',
                        },
                      ]"
                      name="المدينة"
                      label="المدينة"
                      placeholder="اختر المدينة"
                      :options="cities"
                      textLabel="langName"
                      v-model="activitiesDetailsDto.cityId"
                    ></ek-input-select>
                  </b-col>
                  <b-col cols="6">
                    <div class="d-flex align-items-center mt-3">
                      <b-form-checkbox
                        v-model="activitiesDetailsDto.isEmailActive"
                        @input="test($event)"
                        switch
                      >
                      </b-form-checkbox>
                      <label for="">التسجيل باستخدام البريد الالكتروني</label>
                    </div>

                    <validation-provider
                      #default="{ errors }"
                      name="البريد الالكتروني"
                      :rules="
                        activitiesDetailsDto.isEmailActive ? 'required|email' : 'email'
                      "
                      class="email-text-validator d-flex flex-column"
                    >
                      <div class="bold">البريد الالكتروني</div>
                      <b-form-input
                        label="البريد الالكتروني"
                        v-model="activitiesDetailsDto.email"
                        placeholder="ادخل البريد الالكتروني"
                        :state="errors.length > 0 ? false : null"
                        class="text-input mt-1"
                      >
                      </b-form-input>
                      <div class="error-input">
                        {{ errors[0] }}
                      </div>
                    </validation-provider>

                    <ek-input-text
                      label="اسم المستخدم"
                      name="اسم المستخدم"
                      placeholder="ادخل اسم المستخدم"
                      :rules="[
                        {
                          type: 'required',
                        },
                      ]"
                      v-model="activitiesDetailsDto.userName"
                    ></ek-input-text>

                    <div class="password-text-validator">
                      <ek-input-text
                        ref="password"
                        label=" كلمة المرور الجديدة"
                        name="password"
                        placeholder="ادخل كلمة المرور"
                        :rules="[
                          {
                            type: 'min:4',
                            message: 'كلمة المرور يجب ان تكون اطول من 4 محارف',
                          },
                        ]"
                        v-model="activitiesDetailsDto.password"
                      ></ek-input-text>
                    </div>

                    <ek-input-text
                      label="تأكيد المرور"
                      name="تأكيد المرور"
                      placeholder="ادخل كلمة المرور مجددا"
                      v-model="checkPassword"
                      :rules="[
                        {
                          type: 'confirmed:password',
                          message: 'كلمة المرور غير متطابقة',
                        },
                      ]"
                    ></ek-input-text>
                  </b-col>
                </b-row>
                <div class="d-flex justify-content-between ads-details-card__footer mt-1">
                  <div>
                    <b-button class="mr-1" variant="primary" @click="onSubmit"
                      >تعديل</b-button
                    >
                    <b-button variant="outline-primary" @click="returned">تراجع</b-button>
                  </div>
                </div>
              </b-card>
            </ValidationObserver>
          </ek-modal>
          <!-- end edit Activity form  -->
        </div>
      </template>

      <!--start  activity preview info  -->
      <b-row>
        <b-col cols="12" lg="4" md="6">
          <div class="activity-info d-flex">
            <b-img
              style="height: 130px"
              :src="
                $store.getters['app/domainHost'] + '/' + activitiesDetailsDto.imageProfile
              "
              class="act-card__img mt-1 obj-cover"
            ></b-img>
            <div class="activity-info-base mt-3 ml-2">
              <h5>{{ activitiesDetailsDto.accountName }}</h5>
              <h6>
                {{ activitiesDetailsDto.commercialActivityName }}
              </h6>
              <div>
                <b-form-rating
                  readonly
                  style="position: relative; left: 18px"
                  v-model="activitiesDetailsDto.rate"
                  variant="warning"
                  for="rating-md"
                  no-border
                ></b-form-rating>
              </div>
            </div>
          </div>
          <div class="d-flex" style="margin-top: 8px">
            <b-dropdown dropleft variant="flat" toggle-class="p-0" class="custom-dd p-0">
              <template #button-content>
                <unicon name="comment-alt-message" fill="#777" height="16" />معلومات
                التواصل
                <unicon fill="#777" name="angle-left" />
              </template>

              <div v-for="item in activitiesDetailsDto.socialMedia" :key="item.id">
                <b-dropdown-item style="float: left; position: relative">
                  {{ item.title }} &nbsp; &nbsp;
                  {{ item.content }}
                  <unicon fill="gray" height="18px" :name="getIcon(item.title)"></unicon>
                </b-dropdown-item>
              </div>
            </b-dropdown>
          </div>
        </b-col>
        <b-col cols="12" lg="2" md="6">
          <ek-input-textarea
            readonly
            rows="4"
            cols="100"
            label="نبذة عن الفعالية"
            name="نبذة عن الفعالية"
            v-model="activitiesDetailsDto.bio"
          ></ek-input-textarea>
        </b-col>
        <b-col cols="12" lg="3" md="6">
          <ek-input-text
            readonly
            label="البريد الالكتروني"
            name="البريد الإلكتروني"
            v-model="activitiesDetailsDto.email"
          ></ek-input-text>

          <ek-input-text
            readonly
            label="اسم المستخدم"
            name=" اسم المستخدم"
            v-model="activitiesDetailsDto.userName"
          ></ek-input-text>
        </b-col>
        <b-col cols="12" lg="3" md="6">
          <ek-input-text
            readonly
            label="المدينة"
            name="المدينة"
            v-model="activitiesDetailsDto.location"
          ></ek-input-text>

          <ek-date-picker
            disabled
            readonly
            label="تاريخ التسجيل"
            name="تاريخ التسجيل"
            v-model="activitiesDetailsDto.dateCreate"
          ></ek-date-picker>
        </b-col>
      </b-row>
      <!--end activity preview info  -->
    </b-card>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { ValidationProvider } from "vee-validate";

export default {
  data: () => ({
    checkPassword: "",
    country: "",
  }),
  components: {
    ValidationProvider,
  },
  computed: {
    ...mapState({
      activitiesDetailsDto: (state) => state.activities.activitiesDetailsDto,
      commercialActivities: (state) => state.activities.commercialActivities,
      cities: (state) => state.locations.cities,
      countries: (state) => state.locations.countries,
    }),
    validateEmailInput() {
      let emailRules = this.activitiesDetailsDto.phoneNumber
        ? []
        : [
            {
              type: "required",
              message: "الإيميل مطلوب",
            },
            {
              type: "email",
              message: "يجب إدخال البريد الألكتروني بشكل صحيح",
            },
          ];
      console.log("emailRules", emailRules);
      return [...emailRules];
    },
    validatePhoneNumberInput() {
      let phoneNumberRules = this.activitiesDetailsDto.email
        ? []
        : [
            {
              type: "required",
              message: "رقم الهاتف مطلوب",
            },
            {
              type: "mobile",
              message: "يجب ادخال رقم الهاتف بشكل صحيح",
            },
          ];

      console.log("phoneNumberRules", phoneNumberRules);

      return [...phoneNumberRules];
    },
  },
  methods: {
    ...mapMutations(["Update_Activity_Email_Is_Active"]),
    test($event) {
      this.Update_Activity_Email_Is_Active($event);
    },
    ...mapActions([
      "updateActivities",
      "deleteActivity",
      "unBlockAccount",
      "blockAccount",
    ]),
    updateCities($event) {
      this.country = this.countries.find((item) => item.id == $event)?.langName;

      if (this.country != "سوريا") {
        this.activitiesDetailsDto.cityId = null;
      }
    },
    openEdit() {
      this.$refs.edit.open();
    },
    onSubmit() {
      this.$refs.detailsActivity.validate().then((success) => {
        if (success) {
          this.updateActivities({
            userName:this.activitiesDetailsDto.userName,
            accountId: this.activitiesDetailsDto.accountId,
            name: this.activitiesDetailsDto.accountName,
            commercialActivityId: this.activitiesDetailsDto.commercialActivityId,
            email: this.activitiesDetailsDto.email,
            password: this.activitiesDetailsDto.password,
            countryId: this.activitiesDetailsDto.countryId,
            cityId: this.activitiesDetailsDto.cityId,
            file: this.activitiesDetailsDto.file,
            removeFile: this.activitiesDetailsDto.removeFile,
            accountVerification: this.activitiesDetailsDto.accountVerification,
          });
          this.$refs.edit.close();
        }
      });
    },
    returned() {
      this.$refs.edit.close();
    },
    toggleBlock() {
      if (this.activitiesDetailsDto.isBlock) {
        console.log("isBlock");
        this.$store.dispatch("unBlockAccount", this.activitiesDetailsDto.accountId);
        this.activitiesDetailsDto.isBlock = false;
      } else {
        this.blockAccount(this.activitiesDetailsDto.accountId);
        this.activitiesDetailsDto.isBlock = true;
      }
    },
    unBlockAccount() {
      this.activitiesDetailsDto.isBlock = false;
    },
    getIcon(name) {
      if (name == "Facebook") return "facebook-f";
      if (name == "Telegram") return "telegram-alt";
      if (name == "Youtube") return "youtube";
      if (name == "Instagram") return "instagram";
      if (name == "Twitter") return "twitter-alt";
      if (name == "GitHub") return "uniGithubAlt";
      if (name == "WhatsApp") return "uniWhatsapp";
      if (name == "LinkedIn") return "uniLinkedinAlt";
    },
  },
};
</script>
<style lang="scss" scoped>
.email-text-validator {
  margin-top: 2rem;
}

.phone-text-validator {
  margin-top: 1.6rem !important;
}

.password-text-validator {
  margin-top: 0.95rem !important;
}

.text-input {
  margin-top: 0.4rem !important;
}

.bold {
  color: rgb(94, 88, 115);
  font-family: "SSTArabic-Medium";
  font-size: 14px;
  font-weight: 400;
}

.error-input {
  color: rgb(234, 84, 85) !important;
  font-size: 0.857rem;
}

.act-card {
  &__title {
    font-weight: bold;
    font-size: 1.2rem;
    color: #5e5873;
  }

  &__date-range {
    font-weight: normal;
    font-size: 1.2rem;

    color: #5e5873;
  }

  &__img {
    width: 100%;
    border-radius: 6px;
    height: 190px;
  }

  &__ads-content {
    font-weight: normal;
    font-size: 1.2rem;

    color: #5e5873;
  }
}
</style>
