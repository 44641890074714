<template>
    <div class="border-top border-bottom p-1 d-flex align-items-center">
        <div class="visitor-details d-flex align-items-center w-100">
            <div
                class="visitor-details__user-data d-flex align-items-center mr-auto"
            >
                <b-avatar
                    size="lg"
                    class="rounded-circle mr-1"
                    :src="
                        data.imagUrl
                            ? $store.getters['app/domainHost'] +
                              '/' +
                              data.imagUrl
                            : ''
                    "
                >
                </b-avatar>
                <div class="visitor-info mr-auto">
                    <h5>{{ data.name }}</h5>
                    <small>{{ data.location }}</small>
                </div>
            </div>

            <ActivityVisitorDonutStats
                :data="data"
                :height="40"
                class="ml-auto"
            />

            <b-button
                @click="navigateToVisitorDetails(data.accountId)"
                variant="flat-secondary"
                class="visitor-details__arrow-button btn-icon btn-sm rounded-circle ml-1"
            >
                <unicon width="25" name="angle-left" />
            </b-button>
        </div>
    </div>
</template>

<script>
import ActivityVisitorDonutStats from "./stats/ActivityVisitorDonutStats";

export default {
    components: {
        ActivityVisitorDonutStats,
    },
    props: {
        data: {},
    },
    methods: {
        navigateToVisitorDetails(id) {
            this.$router.push(`/admin/users/details/${id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.visitor-details {
    &__arrow-button {
        padding: 1px 1px !important;
    }
}
</style>
