<template>
    <div class="border-top border-bottom p-1 d-flex align-items-center">
        <div class="follower-details d-flex align-items-center w-100">
            <div
                class="follower-details__user-data d-flex align-items-center mr-auto"
            >
                <b-avatar
                    rounded
                    size="sm"
                    fluid
                    class="follower-details__user-image rounded-corners mr-1"
                    :src="
                        data.imagUrl
                            ? $store.getters['app/domainHost'] +
                              '/' +
                              data.imagUrl
                            : ''
                    "
                >
                </b-avatar>
                <div class="follower-info mr-auto">
                    <h5>{{ data.name }}</h5>
                    <small>{{ data.location }}</small>
                </div>
            </div>

            <ActivityVisitorDonutStats
                :data="data"
                :height="40"
                class="ml-auto"
            />

            <div class="follower-details__follow-date ml-1">
                {{ new Date(data.date).toLocaleDateString() }}
            </div>
        </div>
    </div>
</template>

<script>
import ActivityVisitorDonutStats from "./stats/ActivityVisitorDonutStats";

export default {
    components: {
        ActivityVisitorDonutStats,
    },
    props: {
        data: {},
    },
    methods: {
        navigateToVisitorDetails(id) {
            this.$router.push(`/admin/users/details/${id}`);
        },
    },
};
</script>

<style lang="scss" scoped>
.follower-details {
    &__arrow-button {
        padding: 1px 1px !important;
    }

    &__user-image {
        height: 49px;
        width: 49px;
    }
}
</style>
