<template>
  <div class="activity-subscriptions-orders">
    <section class="orders-table">
      <ek-table
        :items="activitySubscriptionOrders"
        :columns="columns"
        @details="navigateToSubscriptionOrderDetails"
        no_delete
        no_select
      >
        <template slot="items.startSub" slot-scope="{ value }">
          {{
            value
              ? new Date(value).toLocaleString("en-UK").split(",").reverse().join(" ")
              : "-"
          }}
        </template>
        <template slot="items.endSub" slot-scope="{ value }">
          {{
            value
              ? new Date(value).toLocaleString("en-UK").split(",").reverse().join(" ")
              : "-"
          }}
        </template>
        <template slot="items.subStatuses" slot-scope="{ value }">
          <StatusBadge
            class="orders-table__status-badge"
            :statusList="activitySubscriptionList"
            :selectedStatusNumber="value"
          />
        </template>

        <template slot="items.details" slot-scope="x">
          <unicon
            width="15"
            height="15"
            name="eye"
            :id="`tooltip-target-${x.props.row.originalIndex}`"
          />

          <b-tooltip
            :target="`tooltip-target-${x.props.row.originalIndex}`"
            triggers="hover"
          >
            <div v-for="(feature, index) in activityFeaturesToString(x.props.row.features)" :key="index">
                {{ feature }}
            </div>
          </b-tooltip>
        </template>
      </ek-table>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge";

export default {
  computed: {
    ...mapGetters(["activitySubscriptionOrders"]),
    ...mapState({
      activitySubscriptionList: (state) => state.activities.activitySubscriptionList,
    }),
  },
  components: {
    StatusBadge,
  },
  props: {
    id: String,
  },
  data: () => ({
    columns: [
      {
        label: "رقم الاشتراك",
        field: "subNumber",
      },
      {
        label: "بداية الاشتراك",
        field: "startSub",
      },
      {
        label: "نهاية الاشتراك",
        field: "endSub",
      },
      {
        label: "حالة الطلب",
        field: "subStatuses",
      },
      {
        label: "تفاصيل",
        field: "details",
        sortable: false,
      },
    ],
    servicesTranslation: {
      services: "خدمات ",
      notification: "إرسال إشعارات ",
      job: "إرسال وظائف ",
      covid: "كورونا ",
    },
  }),
  created() {},
  methods: {
    navigateToSubscriptionOrderDetails({ row }) {
      this.$router.push(`/admin/activities/${this.id}/subscriptions/${row.id}`);
    },
    activityFeaturesToString(arr) {
      let newArr = []

      arr.forEach((item) => {
        newArr.push(this.servicesTranslation[item.toLowerCase()]);
      });

      return newArr;
    },
  },
};
</script>

<style lang="scss" scoped></style>
