<template>
    <b-card body-class="p-0" class="p-0">
        <b-tabs justified>
            <b-tab title="المتابعون">
                <div v-for="item in followers" :key="item.accountId">
                    <ActivityFollower :data="item"/>
                </div>
            </b-tab>

            <b-tab title="الزوار">
                <div v-for="item in visitors" :key="item.accountId">
                    <ActivityVisitor :data="item"/>
                </div>
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>
import ActivityVisitor from "./FollowersAndVisitors/ActivityVisitor.vue";
import ActivityFollower from "./FollowersAndVisitors/ActivityFollower.vue";
export default {
    components: {
        ActivityFollower,
        ActivityVisitor,
    },
    props: {
        followers: Array,
        visitors: Array,
    },
};
</script>
